.gradient-bg {
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.google-sign-in-button {
  transition: background-color 0.3s, box-shadow 0.3s;
}

.google-sign-in-button:hover {
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 2px 4px rgba(0, 0, 0, .25);
}

.google-sign-in-button:active {
  background-color: #eeeeee;
}

.google-sign-in-button:focus {
  outline: none;
  box-shadow: 
    0 -1px 0 rgba(0, 0, 0, .04),
    0 2px 4px rgba(0, 0, 0, .25),
    0 0 0 3px #c8dafc;
}