@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.gradient-bg {
  background: linear-gradient(135deg, #4C1D95 0%, #7C3AED 50%, #EC4899 100%);
}

.feature-card {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

.flashcard-sample img {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.3), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

/* Add smooth scrolling for the entire page */
html {
  scroll-behavior: smooth;
}

/* Custom animation for gradient background */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-gradient-bg {
  background: linear-gradient(270deg, #4C1D95, #7C3AED, #EC4899);
  background-size: 200% 200%;
  animation: gradientAnimation 15s ease infinite;
}

/* Add more custom styles as needed */